import { Link } from "gatsby";
import React, { Component }  from 'react';

export default function Cards(){
    return(
        <div className="text-lg gap-2 max-w-screen-sm w-full flex flex-wrap -m-1">
            <div className="px-4 rounded-full bg-green-500 text-white">Matriz de Habilidades</div>
            <div className="bg-yellow-400 px-4 rounded-full text-gray-700">NineBox</div>
            <div style={{ backgroundColor:'#E71C95' }} className="px-4 rounded-full text-white">Análise</div>
            <div className="px-4 rounded-full bg-green-500 text-white">Trilha de carreira</div>
            <div className="px-4 rounded-full bg-blue-500 text-white">Planos de Desenvolvimento</div>
            <div className="px-4 rounded-full bg-yellow-400 text-gray-700">Acomp. contínuo</div>
        </div>
    );
}
