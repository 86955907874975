import React from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import { Link } from "gatsby";
import FormContato from '../components/Forms/FormContato';
import OnlyEmail from "../components/Forms/OnlyEmail";
import Interesse from "../components/Forms/Interesse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import TypeWriter from "typewriter-effect";

import Cards from "../components/Cards";

const IndexPage = () => {

  return (
    <div>
        <SEO 
            title='Conheça a Plataforma Shelfin'
            description='Acompanhe e desenvolva as COMPETÊNCIAS e HABILIDADES que seus colaboradores necessitam para potencializar os resultados de sua empresa.'
        />

        <div className="w-full min-h-screen flex justify-center space-y-4 mb-8">
            <div className="w-3/4">
                <div className="h-20"></div>
                <div className="flex flex-row w-full mb-4">
                    <Link to="/" class="lg:block hidden">
                        <img className="h-14" src="/images/logo_escura.png" alt="Shelfin"/>
                    </Link>
                    <Link to="/" class="block lg:hidden">
                        <img className="h-10" src="/images/icon.png" alt="Shelfin"/>
                    </Link>
                </div>
                <div class="flex flex-col items-center lg:flex-row w-full mb-4">
                        <div className="w-full lg:w-1/2">
                            <div className="flex flex-col space-y-4 text-gray-800  w-full rounded-3xl p-6" style={{ backgroundColor:'#F1F1F1' }}>
                                <div className="flex flex-col space-y-2">
                                  <div className="w-full text-2xl font-bold">👋 Olá, nós somos a Shelfin!</div>
                                  <div className="text-gray-500">Tenha acesso a essas ferramentas:</div>
                                </div>
                                <Cards/>
                                <Interesse destino="PAGE_LINKEDIN" origem="MATRIZ"/>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <img className="w-full" src="../images/matriz_de_habilidades.png" alt="Matriz de Habilidades"/>
                        </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full p-4 lg:p-10 bg-green-500 rounded-lg">      
                      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="flex flex-col space-y-2  text-white items-center w-full ">                        
                              <div className="w-32 h-32 text-white">
                                  <img src="../images/analise_as_habilidades_em_treinamento.png" alt="Análise as Habilidades em Treinamento" />
                              </div>
                              <div className="w-full text-center text-lg font-base">
                                  Análise as Habilidades em Treinamento
                              </div>
                        </div>
                        <div className="flex flex-col space-y-2  text-white items-center w-full ">                        
                              <div className="w-32 h-32 text-white">
                                  <img src="../images/ajude_no_desenvolvimento_de_carreira_e_no_planejamento_sucessorio.png" alt="Análise as Habilidades em Treinamento" />
                              </div>
                              <div className="w-full text-center text-lg font-base">
                                Ajude no Desenvolvimento de carreira e no planejamento sucessório
                              </div>
                        </div>
                        <div className="flex flex-col space-y-2  text-white items-center w-full ">                        
                              <div className="w-32 h-32 text-white">
                                  <img src="../images/auxilie_na_identificacao_de_habilidades_requeridas.png" alt="Análise as Habilidades em Treinamento" />
                              </div>
                              <div className="w-full text-center text-lg font-base">
                                  Auxilie na Identificação de Habilidades Requeridas
                              </div>
                        </div>
                      </div>
                  </div>
            </div>
        </div>

            {/* <div className="flex items-center justify-center w-full min-h-screen">
                <div className="flex flex-col space-y-8 w-full px-4 lg:px-0 py-10 lg:py-0 lg:w-3/5">

                  <div className="flex items-center justify-center w-full h-64 mb-8 lg:mb-4">
                    <h1 className="text-5xl text-center font-bold">
                          <TypeWriter
                              onInit={(typewriter)=>{
                                  // typewriter.changeDelay(100).typeString("Prepare seus colaboradores para o futuro").pauseFor(2000).deleteChars(6).typeString("agora!").start();
                                  typewriter.changeDelay(50).typeString("Aumente o desempenho de sua equipe com a Matriz de Habilidades da Plataforma da Shelfin!").start();
                              }}
                          />
                      </h1>
                  </div>

                  <div className="h-32 w-full"></div>

                  <div className="w-full px-4 flex items-center justify-center">
                      <div></div>
                  </div>

                  <div className="w-full flex flex-col lg:flex-row  space-x-2 gap-4">

                    <div className="flex flex-col w-full lg:w-1/2 space-y-4 text-gray-800">
                      <div className="text-5xl font-bold">Está interessado em usar a <span className="font-bold italic">Matriz de Habilidades</span> na sua empresa?</div>
                    </div>

                    <div className="flex flex-col w-full lg:w-1/2  space-y-4 p-6 rounded-lg bg-gray-200">
                        <div className="font-medium">
                          Preencha seus dados para que possamos entrar em contato!
                        </div>
                        <Interesse destino="PAGE_LINKEDIN" origem="MATRIZ"/>
                      </div>
                  </div>

                  <div class="flex flex-col items-center lg:flex-row w-full">
                      <div className="w-full lg:w-1/2">
                          <div className="text-gray-800 text-2xl w-full">
                              Com a ferramenta de Matriz de Habilidades é possivel mensurar as competências e habilidades necessárias para que o colaborador possa desempenhar suas funções de forma adequada e atingir objetivos e metas propostas. Sendo possível ter uma visão ampla sobre as <span className="font-bold">competências</span> e <span className="font-bold">habilidades</span> requeridas para o cargo.
                          </div>
                      </div>
                      <div className="w-full lg:w-1/2">
                        <img className="w-full" src="../images/matriz_de_habilidades.png" alt="Matriz de Habilidades"/>
                      </div>
                  </div>
                  <div className="flex flex-col items-center justify-center w-full p-4 lg:p-10 bg-green-500 rounded-lg">
                              
                      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="flex flex-col space-y-2  text-white items-center w-full ">                        
                              <div className="w-32 h-32 text-white">
                                  <img src="../images/analise_as_habilidades_em_treinamento.png" alt="Análise as Habilidades em Treinamento" />
                              </div>
                              <div className="w-full text-center text-lg font-base">
                                  Análise as Habilidades em Treinamento
                              </div>
                        </div>
                        <div className="flex flex-col space-y-2  text-white items-center w-full ">                        
                              <div className="w-32 h-32 text-white">
                                  <img src="../images/ajude_no_desenvolvimento_de_carreira_e_no_planejamento_sucessorio.png" alt="Análise as Habilidades em Treinamento" />
                              </div>
                              <div className="w-full text-center text-lg font-base">
                                Ajude no Desenvolvimento de carreira e no planejamento sucessório
                              </div>
                        </div>
                        <div className="flex flex-col space-y-2  text-white items-center w-full ">                        
                              <div className="w-32 h-32 text-white">
                                  <img src="../images/auxilie_na_identificacao_de_habilidades_requeridas.png" alt="Análise as Habilidades em Treinamento" />
                              </div>
                              <div className="w-full text-center text-lg font-base">
                                  Auxilie na Identificação de Habilidades Requeridas
                              </div>
                        </div>
                      </div>
                  </div>
                  
                  <div className="h-20">
                  </div>
                </div>
            </div> */}
    </div>
  )
}

export default IndexPage